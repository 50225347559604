import { useEffect, useState } from "react";

import { useModal } from "../../../context/modal-context";

import { getLabelPrescription } from "../../../utils/sign";

import RenewalForm from "../RenewalForm";

const ModalRenewalPrescription = ({
  handleSign,
  prescription,
  prescriber,
  handleSignFinish,
}) => {
  const [memberId, setMemberId] = useState(null);
  const { setModalFullScreen, setModalLabel } = useModal();

  useEffect(() => {
    setModalLabel(null);
    if (memberId) {
      setModalFullScreen(true);
    } else {
      setModalFullScreen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberId]);

  return (
    <div className="flex flex-col justify-center w-full h-full p-3">
      <span className="font-bold uppercase text-start">
        renouvellement {getLabelPrescription(prescription.contract.plan.code)}
      </span>
      <div className={`flex flex-col gap-5 ${memberId ? "w-full h-full" : ""}`}>
        {memberId ? (
          <div className="flex flex-col w-full h-full gap-5 mt-3">
            <div
              className="w-full h-full"
              dangerouslySetInnerHTML={{
                __html:
                  "<iframe " +
                  'className="w-full h-full" ' +
                  'src="' +
                  process.env.REACT_APP_YOUSIGN_IFRAME_URL +
                  "procedure/sign?members=" +
                  memberId +
                  "&signatureUi=/signature_uis/" +
                  process.env.REACT_APP_YOUSIGN_UUID +
                  '" style="width:100%;height:100%;" />',
              }}
            />
            <button
              type="submit"
              className="self-end px-3 py-1 font-semibold border rounded w-fit border-dark text-dark hover:bg-dark hover:text-white"
              onClick={handleSignFinish}
            >
              Fermer
            </button>
          </div>
        ) : (
          <RenewalForm
            prescription={prescription}
            prescriber={prescriber}
            onSubmit={handleSign}
            setMemberId={setMemberId}
          />
        )}
      </div>
    </div>
  );
};

export default ModalRenewalPrescription;
